import React from "react";
import EventSponserLogo from "./EventSponserLogo";

const EventSponser = () => {
  var logos = []
  for (var i=1;i < 85;i++) {
    logos.push(<EventSponserLogo url=".#" image={`${i}.jpg`} />)
  }
  return (
    <section className="event_sponser_area sec_pad">
      <div className="container">
        <div className="hosting_title security_title text-center">
          <h2>
            合作商家
          </h2>
        </div>
        <div className="event_sponser_inner">
          <div className="event_sponser_item">
            <div className="row">
              {logos}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EventSponser;
