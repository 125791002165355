import React, {Component} from 'react';
import EventTeamItem from './EventTeamItem';
import Slider from 'react-slick';

class EventTeam extends Component{
    render(){
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 5,
            slidesToScroll: 1,
        };
        return(
            <section className="event_team_area sec_pad">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">优质抖音达人展示</h2>
                </div>
                <Slider ref={c => (this.slider = c)} className="event_team_slider" {...settings}>
                    <EventTeamItem image="team1.jpg" name="甜蜜小烤串" post="1万粉丝"/>
                    <EventTeamItem image="team2.jpg" name="乌一七" post="1.7万粉丝"/>
                    <EventTeamItem image="team3.jpg" name="司徒宝宝" post="10.1万粉丝"/>
                    <EventTeamItem image="team4.jpg" name="SukiBB" post="3.2万粉丝"/>
                    <EventTeamItem image="team5.jpg" name="吃货女友在广州" post="2.1万粉丝"/>
                    <EventTeamItem image="team6.jpg" name="大表姐探广州" post="5.7万粉丝"/>
                    <EventTeamItem image="team7.jpg" name="惠食fafa" post="1.5万粉丝"/>
                    <EventTeamItem image="team8.jpg" name="莎姐广州吃货" post="9.4万粉丝"/>
                    <EventTeamItem image="team9.jpg" name="不会吃的K桑." post="2.2万粉丝"/>
                </Slider>
            </div>
        </section>
        )
    }
}

export default EventTeam;