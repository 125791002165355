import React, { Component } from "react";

class EventSponserLogo extends Component {
  render() {
    let { image, url } = this.props;
    return (
      <div>
        <a href={url} className="analytices_logo wow fadeInUp">
          <img src={require("../img/home-event/logo_client/" + image)} alt="" />
        </a>
      </div>
    );
  }
}
export default EventSponserLogo;
