import React from "react";

const EventFact = () => {
  return (
    <section style={{marginTop: "50px"}} className="event_fact_area sec_pad">
      <div className="container">
        <div className="hosting_title security_title text-center">
          <h2>
            <span>操作流程</span> 简单方便快捷
          </h2>
        </div>
        <div className="seo_fact_info">
          <div className="seo_fact_item wow fadeIn">
            <div className="text">
              <div className="counter one">商家</div>
              <p>发布活动</p>
            </div>
          </div>
          <div className="seo_fact_item wow fadeIn" data-wow-delay="0.3s">
            <div className="text">
              <div className="counter two">达人</div>
              <p>一键接单</p>
            </div>
          </div>
          <div className="seo_fact_item wow fadeIn" data-wow-delay="0.6s">
            <div className="text">
              <div className="counter three">发布</div>
              <p>视频/图文</p>
            </div>
          </div>
          <div className="seo_fact_item last wow fadeIn" data-wow-delay="0.9s">
            <div className="text">
              <div className="counter four">门店</div>
              <p>快速曝光</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EventFact;
