import React from 'react';
import EventFeaturesItem from './EventFeaturesItem';

const EventFeatures =()=>{
    return(
        <section className="event_features_area">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>入驻平台</span>低成本曝光</h2>
                </div>
                <div className="row event_features_inner">
                    <EventFeaturesItem url="" image="cpfb.png"  title="成片分发" ptext="无需达人剪辑"/>
                    <EventFeaturesItem url="" image="ygtg.png"  title="远程发布" ptext="相同支出，更多达人"/>
                    <EventFeaturesItem url="" image="ycfb.png"  title="员工推广" ptext="矩阵发布，更强曝光"/>
                </div>
            </div>
        </section>
    )
}
export default EventFeatures;