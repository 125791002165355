import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal';
import {Link} from 'react-router-dom';

class FooterErp extends Component {
    render(){
        let FooterData = this.props.FooterData;
        let{fClass} = this.props;
        return(
            <footer className={`footer_area h_footer_dark ${fClass}`}>
                <div className="container">
                    <div className="row">
                        {
                            FooterData.CompanyWidget.map(widget=>{
                                return(
                                    <Reveal effect="fadeInLeft" duration={500} key={1}>
                                        <div className="col-lg-4 col-sm-6" >
                                            <div className="f_widget dark_widget company_widget" data-wow-delay="0.2s">
                                                <a href="/" className="f-logo"><img style={{width: '163px'}} src={require ("../../img/logo.png")} alt=""/></a>
                                                <p>© 2023 广州佰傲文化传媒有限责任公司</p>
                                                <p><a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2021154405号-2</a></p>
                                                {/*<div className="f_social_icon">*/}
                                                {/*    {*/}
                                                {/*        FooterData.socialIcon.map(item =>{*/}
                                                {/*            return(*/}
                                                {/*                <a href="/" key={item.id}><i className={item.icon}></i></a>*/}
                                                {/*            )*/}
                                                {/*        })*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </Reveal>
                                )
                            })
                        }

                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterErp;